<template>
  <div>
    <Newheader />
    <div class="flex-col page">
      <div class="flex-col space-y-81 section">
        <div class="flex-col items-center image-wrapper">
          <img class="image"
            src="../assets/images/news1.png" />
          <!-- <img class="image_2" -->
            <!-- src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16654591253838560808.png" /> -->
        </div>
        <div class="flex-col space-y-81 group">
          <div class="flex-row justify-between" >
            <span class="font_1 text" style="font-family: Albbr;">随时随地 发现光阳</span>
            <div class="flex-row space-x-96 group_2">
              <img class="image_3"
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16654591253822433262.png" />
              <img class="image_4"
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16654591253838544491.png" />
            </div>
          </div>
          <div  class="swiper swiper1">
          <div class="swiper-wrapper">
            <div v-for="(item,index) in list" :key="index" class="swiper-slide">
          <a :href="item.href">
          <div class="flex-col">
            <div class="flex-row justify-between">
              <a :href="item.href">
                <img class="image_5"
                :src="item.img" />
              </a>
            </div>
            <div class="flex-row justify-between group_3">
              <div class="group_4">
                <span class="font_1" style="font-family:'Albb'">
                  {{item.text1}}
                  <br />
                </span>
                <span class="font_1" style="font-family:'Albb'">{{item.text2}}</span>
              </div>
            </div>
            <div class="flex-row justify-between group_5">
              <span class="font_2" style="font-family:'Albb'">{{item.sj}}</span>
            </div>
          </div>
        </a>
        </div></div></div>

        </div>
      </div>
    </div>
    <newfooter />
  </div>
</template>

<script>
export default {
  name: 'Vue3News',

  data() {
    return {
      list:[
      {
        href:"https://mp.weixin.qq.com/s/oW_ooDGLWMcAsMpBkIUUOA",
        img:require("../assets/images/news-swiper7.png"),
        text1:"燃爆夏日 热爱无限",
        text2:"百余潮酷玩家赛场竞技PK",
        sj:"2022.8.23"
      },
      {
        href:"https://mp.weixin.qq.com/s/sIuTdiFnTfEBITpNI2NpGw",
        img:require("../assets/images/news-swiper6.png"),
        text1:"S7线上发布会精彩全回顾",
        text2:"",
        sj:"2022.5.27"
      },
      {
        href:"https://mp.weixin.qq.com/s/cEWPNX3HwnvwuHjz9mGcrw",
        img:require("../assets/images/news-swiper5.png"),
        text1:"你说耀有光",
        text2:"S7线上发布会精彩全回顾",
        sj:"2022.5.27"
      },
      {
        href:"https://mp.weixin.qq.com/s/phrfCaxdfg2DqS4xdJ0mxw",
        img:require("../assets/images/news-swiper4.png"),
        text1:"四城你好!",
        text2:"礼”已备好，等您领取",
        sj:"2022.4.27"
      },
      {
        href:"https://mp.weixin.qq.com/s/AXD1wj5wjARzTH6f-6i4Ig",
        img:require("../assets/images/news-swiper3.png"),
        text1:"新速造!KYMCO光阳电动携全系产品",
        text2:"强新登陆海南EV大汇！",
        sj:"2022.1.3"
      },  
      {
        href:"https://mp.weixin.qq.com/s/Wg03ieOH4koiVmmmyEUNng",
        img:require("../assets/images/news-swiper2.png"),
        text1:"KYMCO光阳电动",
        text2:"豪华产品矩阵闪耀2021北京国际摩展",
        sj:"2021.5.29"
      },  
      {
        href:"https://mp.weixin.qq.com/s/LZn-0RKzXDbeh4DEnFWk0w",
        img:require("../assets/images/news-swiper1.png"),
        text1:"你好，光阳电动！",
        text2:"时代传奇 实在传承，[光阳电动]  元气登场",
        sj:"2021.4.29"
      }
    ]
    };
  },

  mounted() {
       this.$nextTick(()=>{
        new Swiper('.swiper1', {
          loop:true,
          slidesPerView : 2,
          spaceBetween : 100,
          navigation: {
      nextEl: '.image_4',
      prevEl: '.image_3',
    },
        
      }
      )
       })
  },

  methods: {

  },
};
</script>

<style scoped>
.page {
  padding-bottom: 135px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.space-y-81>*:not(:first-child) {
  margin-top: 81px;
}

.section {
  padding-bottom: 0;
  background-color: #ffffff;
}

.image-wrapper {
  position: relative;
}

.image {
  width: 100vw;
  height: 500.00064000000003px;
  object-fit: cover;
}

.image_2 {
  width: 457px;
  height: 43px;
  position: absolute;
  left: 100px;
  bottom: 54px;
}

.group {
  padding-left: 100px;
}

.font_1 {
  font-size: 32px;
  font-family: AlibabaPuHuiTiR;
  line-height: 48px;
  color: #000000;
}

.text {
  line-height: 34px;
}

.space-x-96>*:not(:first-child) {
  margin-left: 96px;
}

.group_2 {
  margin: 5px 207px 5px 0;
  cursor: pointer;
}

.image_3 {
  width: 28px;
  height: 24px;
}

.image_4 {
  width: 29px;
  height: 24px;
}

.image_5 {
  width: 881px;
  height: 600px;
}

.image_6 {
  width: 801px;
  height: 600px;
}

.group_3 {
  margin-top: 27px;
  align-self: flex-start;
  width: 1617px;
}

.group_4 {
  height: 96px;
}

.group_5 {
  margin-top: 40px;
  align-self: flex-start;
  width: 1108px;
}

.font_2 {
  font-size: 18px;
  font-family: AlibabaPuHuiTiR;
  line-height: 14px;
  color: #000000;
}
</style>